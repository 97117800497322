import { graphql } from 'gatsby';
import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Banner from 'src/components/Banner';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';

interface TCProps {
  data: any;
}

const TCPage: FC<TCProps> = ({ data }) => {
  const {
    wpPage: { tcFields: acfFields, seo },
    banner,
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={seo.opengraphImage}
      />

      <Banner
        image={banner.childImageSharp.gatsbyImageData}
        title="Pirkimo sąlygos ir taisyklės"
      />
      <Main>
        <div dangerouslySetInnerHTML={{ __html: acfFields.main }} />
        <div
          id="mokejimo-budai"
          dangerouslySetInnerHTML={{ __html: acfFields.payment }}
        />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query TC {
    wpPage(slug: { eq: "pirkimo-salygos-ir-taisykles" }) {
      ...YoastSeoPage
      tcFields {
        main
        payment
      }
    }
    banner: file(relativePath: { eq: "pirkimo-salygos.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

export default TCPage;
